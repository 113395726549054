exports.components = {
  "component---src-pages-2010-js": () => import("./../../../src/pages/2010.js" /* webpackChunkName: "component---src-pages-2010-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-83-js": () => import("./../../../src/pages/83.js" /* webpackChunkName: "component---src-pages-83-js" */),
  "component---src-pages-banh-keo-tet-jsx": () => import("./../../../src/pages/banh-keo-tet.jsx" /* webpackChunkName: "component---src-pages-banh-keo-tet-jsx" */),
  "component---src-pages-banh-sinh-nhat-js": () => import("./../../../src/pages/banh-sinh-nhat.js" /* webpackChunkName: "component---src-pages-banh-sinh-nhat-js" */),
  "component---src-pages-banh-trung-thu-js": () => import("./../../../src/pages/banh-trung-thu.js" /* webpackChunkName: "component---src-pages-banh-trung-thu-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-chinh-sach-bao-mat-js": () => import("./../../../src/pages/chinh-sach-bao-mat.js" /* webpackChunkName: "component---src-pages-chinh-sach-bao-mat-js" */),
  "component---src-pages-dev-products-js": () => import("./../../../src/pages/dev/products.js" /* webpackChunkName: "component---src-pages-dev-products-js" */),
  "component---src-pages-dieu-khoan-js": () => import("./../../../src/pages/dieu-khoan.js" /* webpackChunkName: "component---src-pages-dieu-khoan-js" */),
  "component---src-pages-get-voucher-js": () => import("./../../../src/pages/get-voucher.js" /* webpackChunkName: "component---src-pages-get-voucher-js" */),
  "component---src-pages-gioi-thieu-js": () => import("./../../../src/pages/gioi-thieu.js" /* webpackChunkName: "component---src-pages-gioi-thieu-js" */),
  "component---src-pages-hoat-dong-xa-hoi-jsx": () => import("./../../../src/pages/hoat-dong-xa-hoi.jsx" /* webpackChunkName: "component---src-pages-hoat-dong-xa-hoi-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-banh-sinh-nhat-js": () => import("./../../../src/pages/menu-banh-sinh-nhat.js" /* webpackChunkName: "component---src-pages-menu-banh-sinh-nhat-js" */),
  "component---src-pages-orders-tracking-js": () => import("./../../../src/pages/orders/tracking.js" /* webpackChunkName: "component---src-pages-orders-tracking-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-product-id-js": () => import("./../../../src/pages/products/[productId].js" /* webpackChunkName: "component---src-pages-products-product-id-js" */),
  "component---src-pages-socola-jsx": () => import("./../../../src/pages/socola.jsx" /* webpackChunkName: "component---src-pages-socola-jsx" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-voucher-js": () => import("./../../../src/pages/voucher.js" /* webpackChunkName: "component---src-pages-voucher-js" */)
}

